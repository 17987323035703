import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';


function VerifyPopUp(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className={props.className} onClick={handleShow}>
     
        {props.showIcon && <i className="icon-varified font-16 me-2"></i>}
        {props.name}
      </span>

      <Modal
        className="verifyModal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
         <Modal.Body className="p-4 p-sm-4 p-md-5">
         
          <h5 className="modal-title font-24 text-dark-blue text-center w-100 mb-4" id="staticBackdropLabel">
  
          Let’s verify your  {props.title}
            </h5>
          <Button
            variant="close position-absolute top-20 right-20"
            onClick={handleClose}
          ></Button>   

<div className="p-4 bg-gray radius-10 mx-2">
              <p className="font-16 text-gray-41 text-center">
                Please enter the OTP sent to
              </p>
              <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                <span className="font-24 font-500">{props.verify}</span>
                <button className="btn-edit mt-3 mt-sm-0">
                <i className="fa-regular fa-pen-to-square me-1"></i>
                  Edit {props.edit}
                </button>
              </div>
              <div className="d-flex justify-content-center my-4">
                <input className="input-fieldss input-otp text-center" type="text" placeholder="" name="otp" required="" maxlength="1"/>

                <input className="input-fieldss input-otp text-center" type="text" placeholder="" name="otp" required="" maxlength="1"/>

                <input className="input-fieldss input-otp text-center" type="text" placeholder="" name="otp" required="" maxlength="1"/>

                <input className="input-fieldss input-otp text-center" type="text" placeholder="" name="otp" required="" maxlength="1"/>
              </div>

              <div className='d-flex justify-content-center'>
              <button href="javascript:;" className="btn btn-green px-5 mx-auto radius-25">
                <span>Verify</span>
              </button>

              </div>
              
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center py-3">
              <p className="font-16 text-gray-41 m-0">Didn’t receive the OTP?</p>
              <a href="javascript:;" className="btn-border-gray width-97 mx-auto my-2">
                <span className="position-relative">Resend</span>
              </a>
              <span className="font-14 text-gray-41 fst-italic">
                Resend after 1:30 minutes
              </span>
            </div>

          
        </Modal.Body>

      

      </Modal>
    </>
  );
}

export default VerifyPopUp;
