import React from 'react';
import { useNavigate } from 'react-router-dom';

function PostPropertyStatus() {

  const navigate = useNavigate(); // Initialize useNavigate hook
      
  // Handle the button click event
  const handleClickBasicDetails = () => {
    navigate('/post-your-property'); // Navigate to the /rice-details page
  };

  // Handle the button click event
  const handleClickPropertyDetails = () => {
    navigate('/post-your-property-detail'); // Navigate to the /rice-details page
  };

  // Handle the button click event
  const handleClickUploadDocuments = () => {
    navigate('/post-your-property-document'); // Navigate to the /rice-details page
  };

  // Handle the button click event
  const handleClickPriceDetails = () => {
    navigate('/post-your-property-price-detail'); // Navigate to the /rice-details page
  };

  return (
    <>
      <div className="postPropertyStatus">
                <div className='postPropertyStatusDetail'>
                  <a href="javascript:;" className="btn-back">
                    <i className="bi bi-chevron-left me-1"></i>
                    <span>Return to dashboard</span>
                  </a>

                  <div className="postPropertyStatusPercentage">
                    <h4>Post your property</h4>
                    <p>Sell or rent your property</p>

                    <div className='w-100'>
                    <div className='row g-2 align-items-centet'>
                        <div className='col d-flex align-items-center'>
                        <div
                      className="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar"
                        style={{ width: "10%" }}
                      ></div>
                    </div>
                        </div>
                        <div className='col-auto'>10%</div>

                    </div>
                    </div>
                    
                  </div>

                  <ul className="steps">
                    <li className="completed">
                      <i></i>
                      <div className="name-status" onClick={handleClickBasicDetails}>
                        <h5>Basic Details</h5>
                        <span>Completed</span>
                      </div>
                    </li>
                    <li className='inProgress'>
                      <i></i>
                      <div className="name-status" onClick={handleClickPropertyDetails}>
                        <h5>Property Details</h5>
                        <span>In progress</span>
                      </div>
                    </li>
                    <li>
                      <i></i>
                      <div className="name-status" onClick={handleClickUploadDocuments}>
                        <h5>Upload Documents</h5>
                        <span>Pending</span>
                      </div>
                    </li>
                    <li>
                      <i></i>
                      <div className="name-status" onClick={handleClickPriceDetails}>
                        <h5>Price Details</h5>
                        <span>Pending</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='quickpost'>
                    Now post your property via <i className="bi bi-whatsapp text-green"></i> Whatsapp. Send &quot;Hi&quot; to <a href="https://wa.me/9030127112?text=I'm%20interested%20in%20Posting%20my%20property">9030127112</a> or <a className='' href='javascritp:;'>Click</a>
                </div>
              </div>
    </>
  )
}

export default PostPropertyStatus;
