import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Import CSS and Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/univariety.css';
import './css/success-style.css';

// Import Components
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Component/Home/Home';
import ProfilePage from './Component/ProfilePage/ProfilePage';

import ChangePassword from './Component/ProfilePage/ChangePassword.tsx';
import ProfileViewEdit from './Component/ProfilePage/ProfileViewEdit.js';

import OfferPackage from './Component/OfferPackage/OfferPackage.tsx';
import PostYourProperty from './Component/PostYourProperty/PostYourProperty.tsx';
import PostPropertyDetails from './Component/PostYourProperty/PostPropertyDetails.tsx';
import PostPropertyDocuments from './Component/PostYourProperty/PostPropertyDocuments.tsx';
import PostPriceDetails from './Component/PostYourProperty/PostPriceDetails.tsx';
import PropertyShowCase from './Component/PropertyShowCase/PropertyShowCase.tsx';

import AgentPage from './Component/Agentpage/AgentPage.tsx';


function App() {
  return (
    <>
      <Header />
      <main className="flex-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/offer" element={<OfferPackage />} />
          <Route path="/editprofile/:userId" element={<ProfileViewEdit />} />
          <Route path="/changepassword/:userId" element={<ChangePassword />} />
          <Route path="/post-your-property" element={<PostYourProperty />} />
          <Route path="/post-your-property-detail" element={<PostPropertyDetails />} />
          <Route path="/post-your-property-document" element={<PostPropertyDocuments/>} />
          <Route path="/post-your-property-price-detail" element={<PostPriceDetails />} />
          <Route path="/property-show-case" element={<PropertyShowCase />} />
          <Route path="/AgentPage" element={<AgentPage />} />
          
          {/* Add other routes as needed */}
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
