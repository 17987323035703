import React from 'react';
import Subscription from './Subscription.tsx';


function OfferPackage() {
  return (
    <>
    <div className='p-5 text-center bg-info bg-gradient rounded-5 rounded-top-0' >
        <h1 className='text-center mb-0 font-400 font-24'>Find the right package for your <span className='font-500'>Residential listing</span></h1>
    </div>
    <div className='max-1140 mx-auto py-5'>
        <Subscription/>
    </div>
    </>
  )
}

export default OfferPackage;
