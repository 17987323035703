import React from 'react';

function ChangePassword() {
  return (
    <>
      <div className="tab-white-block">
        <div className="tab-white-block-header">
          <div className="row g-2 align-items-center">
            <div className="col text-start">
              <h2 className="text-start mb-0 font-22 font-500">Change Password</h2>
            </div>          
          </div>
        </div>
        <div className="tab-white-block-body">
            <div className='max-360'>
                <div className='row g-3'>
                <div className="col-12">
                        <input type="text" className='form-control height-50' placeholder='Current  Password' />
                    </div>
                    <div className="col-12">
                        <input type="text" className='form-control height-50' placeholder='New Password' />
                    </div>
                    <div className="col-12">
                        <input type="password" className='form-control height-50' placeholder='Confirm Password' />
                    </div>
                    <div className="col-12">
                        <div className='row g-3'>
                            <div className="col-auto"><button className='btn btn-green radius-25 height-45'>Update</button></div>
                            <div className="col-auto"><button className='btn btn-cancel radius-25 height-45'>Cancel</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
