import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // React Router for navigation
import axios from 'axios';
import logoImg from '../images/main-logo.png';

import FormPopUp from './FormPopUp';
import FormRegisterPopUp from './FormRegisterPopUp';

function Navigation() {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = { webpage: 2 }; // Define the request payload
        const response = await axios.post(
          'https://www.astsoftwaresolutions.in/api/products/categoryList',
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          console.log(response.data, 'response data');
          // Handle successful response
          // window.location.reload(); Uncomment if needed
        } else {
          setErrorMessage('Failed to fetch data. Please try again.');
        }
      } catch (error) {
        if (error.response) {
          setErrorMessage(error.response.data.message || 'An error occurred');
        } else {
          setErrorMessage('Network error. Please try again later.');
        }
      }
    };

    fetchData();
  }, []); // Empty dependency array to ensure it runs once on mount

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // React Router navigation hook

  // Check login state on component mount
    useEffect(() => {
      const token = sessionStorage.getItem('token');
      setIsLoggedIn(!!token); // If token exists, user is logged in
    }, []);
  
    // Logout function
    const handleLogout = () => {
      sessionStorage.clear(); // Clear all session data
      setIsLoggedIn(false); // Update state
      navigate('/'); // Navigate to home after logout
    };
  
    // Navigate to profile page
    const goToProfile = () => {
      navigate('/profile'); // Navigate to profile route
    };

  return (
    <div className="col-xl col-sm-auto col d-flex justify-content-end">
      <button
        className="navbar-toggler nav-toggle"
        type="button"
        data-bs-toggle="offcanvas"
        href="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"><b></b></span>
      </button>
      <div className="offcanvas offcanvas-start" id="navbarNavDropdown">
        <div className="mobile-new-header d-block d-xl-none">
          <div className="row g-3">
            <div className="col">
              <a className="navbar-brand" href="/">
                <img src={logoImg} alt="univariety" />
              </a>
            </div>
            <div className="col-auto d-flex align-items-center">
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="col-12 d-block d-sm-none">
            {isLoggedIn ? (
                      // Show Profile and Logout buttons if logged in
                      <>
                        <button
                          className="btn btn-register me-2 border-0"
                          onClick={goToProfile}
                        >
                          <span>Profile</span>
                        </button>
                        <button
                          className="btn btn-login me-2 border-0"
                          onClick={handleLogout}
                        >
                          <span>Logout</span>
                        </button>
                      </>
                    ) : (
                      // Show Login and Register buttons if not logged in
                      <>
                        <FormPopUp className="btn btn-login me-2 border-0" name="Login" />
                        <FormRegisterPopUp className="btn btn-register border-0" name="Register" />
                      </>
                    )}
             
            </div>
          </div>
        </div>
        <ul
          className="navbar-nav justify-content-md-center"
        >
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="/">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about-us">
              About Us
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="collapse"
              href="#collapseSolutions"
              aria-expanded="false"
            >
              Category
            </a>
            <div className="collapse dropdown-list" id="collapseSolutions">
              <div className="row g-0 g-xl-3">
                {/* Add category lists here */}
              </div>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/articles">
              Articles
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/news">
              News
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/reviews">
              Reviews
            </a>
          </li>
        </ul>
      </div>
      {/* {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} */}
    </div>
  );
}

export default Navigation;
