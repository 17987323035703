import React, { useEffect, useRef, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import AgentDetail from './AgentDetail.tsx';
import AboutAgent from './AboutAgent.tsx';
import ActiveProperties from './ActiveProperties.tsx';

import ProfileViewEdit from './../ProfilePage/ProfileViewEdit.js';
import ChangePassword from './../ProfilePage/ChangePassword.tsx';  
import ProfilePropertyList from './../ProfilePage/ProfilePropertyList.tsx';
import SubscriptionPackage from './../ProfilePage/SubscriptionPackage.tsx';


const AgentPage = () => {

  return (
    <>
      <div className='agentWrapper'>
      
              
    
        <section className="container-fluid bg_grdGold py-5">
          <AgentDetail/>
        </section>
        
        <section className="alumni_profile_detail_wrap container-fluid" id="alumni_profile_detail_wrap">
        <div className="max-1140 mx-auto alumni_profile_detail">
          <div className="alumni_profile_brife_tab mb-4">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-preferences-tab" data-bs-toggle="pill" data-bs-target="#pills-preferences" type="button" role="tab" aria-controls="pills-preferences" aria-selected="true">Profile Details</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-changepassword-tab" data-bs-toggle="pill" data-bs-target="#pills-changepassword" type="button" role="tab" aria-controls="pills-changepassword" aria-selected="false">Change Password</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-propertylist-tab" data-bs-toggle="pill" data-bs-target="#pills-propertylist" type="button" role="tab" aria-controls="pills-propertylist" aria-selected="false">Property List</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-subscriptionpackage-tab" data-bs-toggle="pill" data-bs-target="#pills-subscriptionpackage" type="button" role="tab" aria-controls="pills-subscriptionpackage" aria-selected="false">Subscription package</button>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-preferences" role="tabpanel" aria-labelledby="pills-preferences-tab">
              <ProfileViewEdit />
            </div>

            <div className="tab-pane fade" id="pills-changepassword" role="tabpanel" aria-labelledby="pills-changepassword-tab">
              <ChangePassword/>
            </div>

            <div className="tab-pane fade" id="pills-propertylist" role="tabpanel" aria-labelledby="pills-propertylist-tab">
              <ProfilePropertyList/>
            </div>

            <div className="tab-pane fade" id="pills-subscriptionpackage" role="tabpanel" aria-labelledby="pills-subscriptionpackage-tab">
              <SubscriptionPackage/>
            </div>
          </div>
        </div>
      </section>
        
        {/* <ActiveProperties/> */}

      </div>
    </>
  );
};

export default AgentPage;
