import React from 'react';
import { useNavigate } from 'react-router-dom';
import FormModelPopup from '../FormModelPopup.js';

import imgApartment from './../../../src/images/building.svg';
import imgFloor from './../../../src/images/tiles.svg';
import imgHouse from './../../../src/images/home.svg';
import imgVilla from './../../../src/images/villa.svg';

import imgFullyFurnished from './../../../src/images/fully-furnished.svg';
import imgSemiFurnished from './../../../src/images/semi-furnished.svg';
import imgUnFurnished from './../../../src/images/unfurnished.svg';

function AddPropertyDetails() {
    const navigate = useNavigate(); // Initialize useNavigate hook

  // Handle the button click event
  const handleClick = () => {
    navigate('/post-your-property-document'); // Navigate to the /rice-details page
  };

  return (
    <>
      <div className='row g-4'>
                    <div className="col-12">
                        <a className='font-24 mb-0 text-start title-link' href='/post-your-property'><i className="bi bi-chevron-left me-2"></i>Add Property Details</a>
                    </div>
                    <div className="col-12">
                        <label  className="form-label">Property Type</label>
                        <div className='row g-3'>
                        <div className="col-auto">
                                <div className="form-check rectBox icon-block h-100">
                                    <input className="form-check-input" type="radio" name="PropertyType" id="Apartment"/>
                                    <label className="form-check-label" htmlFor="Apartment">
                                        <i><img src={imgApartment} className='' alt="" /></i>
                                        <span>Apartment</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox icon-block h-100">
                                    <input className="form-check-input" type="radio" name="PropertyType" id="IndependentFloor"/>
                                    <label className="form-check-label" htmlFor="IndependentFloor">
                                        <i><img src={imgFloor} className='' alt="" /></i>
                                        <span>Independent Floor</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox icon-block h-100">
                                    <input className="form-check-input" type="radio" name="PropertyType" id="IndependentHouse"/>
                                    <label className="form-check-label" htmlFor="IndependentHouse">
                                        <i><img src={imgHouse} className='' alt="" /></i>
                                        <span>Independent House</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox icon-block h-100">
                                    <input className="form-check-input" type="radio" name="PropertyType" id="Villa"/>
                                    <label className="form-check-label" htmlFor="Villa">
                                        <i className=''><img src={imgVilla} className='' alt="" /></i>
                                        <span>Villa</span>
                                    </label>
                                </div>
                            </div>
                        
                        </div>
                    </div>

                    <div className="col-12">
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Bulidlng/'Project/'Soclety (Optionai)"/>
                    </div>

                    <div className="col-12">
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Locality"/>
                    </div>
                    
                    <div className="col-12">
                        <label  className="form-label">BHK</label>
                        <div className='row g-3'>
                        <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="bhk" id="1RK"/>
                                    <label className="form-check-label" htmlFor="1RK">
                                        1 RK
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="bhk" id="1BHK"/>
                                    <label className="form-check-label" htmlFor="1BHK">
                                        1 BHK
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="bhk" id="1.5BHK"/>
                                    <label className="form-check-label" htmlFor="1.5BHK">
                                        1.5 BHK
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="bhk" id="2BHK"/>
                                    <label className="form-check-label" htmlFor="2BHK">
                                        2 BHK
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="bhk" id="3+BHK"/>
                                    <label className="form-check-label" htmlFor="3+BHK">
                                        3+ BHK
                                    </label>
                                </div>
                            </div>
                        
                        </div>
                    </div>

                    <div className="col-12">
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Built Up Area"/>
                    </div>

                    <div className="col-12">
                        <label  className="form-label">Furnish Type</label>
                        <div className='row g-3'>
                        <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="FurnishType" id="FullyFurnished"/>
                                    <label className="form-check-label" htmlFor="FullyFurnished">
                                        <i className="icon me-2"><img src={imgFullyFurnished} width={30} alt="" /></i>
                                        <span>Fully Furnished</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="FurnishType" id="SemiFurnished"/>
                                    <label className="form-check-label" htmlFor="SemiFurnished">
                                        <i className="icon me-2"><img src={imgSemiFurnished} width={30} alt="" /></i>
                                        <span>Semi Furnished</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="FurnishType" id="Unfurnished"/>
                                    <label className="form-check-label" htmlFor="Unfurnished">
                                        <i className="icon me-2"><img src={imgUnFurnished} width={30} alt="" /></i>
                                        <span>Unfurnished</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                {/* <a href="javascript:;" className='font-18 font-600'>+ Add Furnishings / Amenities</a> */}
                                <FormModelPopup className="font-18 font-600 text-info" linkText="+ Add Furnishings / Amenities" />
                                
                            </div>
                    
                
                        
                        </div>
                    </div>

               
                    <div className="col-12">
                        <div className="form-check custom-check">
                            <input className="form-check-input" type="checkbox" value="" id="Sharelistinginformationwithagents"/>
                            <label className="form-check-label" htmlFor="Sharelistinginformationwithagents">
                                Share listing information with agents
                            </label>
                        </div>
                    </div>

                    <div className="col-12">
                        <button className="btn btn-big-green" type="submit" onClick={handleClick}>Next. add property details</button>
                    </div>

                </div>
    </>
  )
}

export default AddPropertyDetails;
