import React from 'react';
import Subscription from '../OfferPackage/Subscription.tsx';

function SubscriptionPackage() {
  return (
    <>
      <div className="tab-white-block">
        <div className="tab-white-block-header">
          <div className="row g-2 align-items-center">
            <div className="col text-start">
              <h2 className="text-start mb-0 font-22 font-500">Subscription Package</h2>
            </div>          
          </div>
        </div>
        <div className="tab-white-block-body">
            <div className='w-100'>
                <div className='row g-3'>
                    <div className="col-12">
                    </div>
                    <div className="col-12">
                       <Subscription/>
                    </div>
                   
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionPackage;
