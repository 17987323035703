import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CitySearch = ({ onCitySelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cities, setCities] = useState([]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCityClick = (city) => {
    onCitySelect(city); // Pass selected city to parent
    setSearchTerm(city.name); // Update the input field with the selected city
    setCities([]); // Clear city list after selection
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const fetchCities = async () => {
        try {
          const response = await axios.get(
            `http://localhost:3019/api/products/cities?search=${searchTerm}`
          );
          setCities(response.data);
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      };
      fetchCities();
    } else {
      setCities([]);
    }
  }, [searchTerm]);

  return (
    <div>
      <input
        type="text"
        className="form-control"
        placeholder="Search City"
        value={searchTerm}
        onChange={handleChange}
      />
      <ul className="list-group mt-3">
        {cities.map((city) => (
          <li
            key={city.id}
            className="list-group-item"
            onClick={() => handleCityClick(city)}
            style={{ cursor: 'pointer' }}
          >
            {city.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CitySearch;
