import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import imgDiningTable from './../images/dinning-table.svg';
import imgWashingMachine from './../images/washing-machine.svg';
import imgSofa from './../images/seater-sofa.svg';
import imgMicrowave from './../images/oven.svg';

import imgStove from './../images/hob.svg';
import imgFridge from './../images/fridge.svg';
import imgWaterPurifier from './../images/appliance.svg';
import imgGasPipeline from './../images/oven.svg';
import imgAc from './../images/air-conditioner.svg';
import imgBed from './../images/single-bed.svg';
import imgTv from './../images/television.svg';
import imgCupboard from './../images/cupboard.svg';
import imgGeyser from './../images/oil.svg';

import imgLift from './../images/elevator.svg';
import imgCctv from './../images/security-camera.svg';
import imgGym from './../images/weight.svg';
import imgGarden from './../images/flowers.svg';
import imgKidsArea from './../images/play-area.svg';
import imgSports from './../images/sports.svg';
import imgSwimmingPool from './../images/swimming-pool.svg';
import imgIntercom from './../images/telephone.svg';
import imgGatedCommunity from './../images/gate.svg';
import imgClubHouse from './../images/golf-club.svg';

function FormModelPopup(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (event) => {
    setShow(true);
  }


  return (
    <>
        <a href="javascript:;"  className={props.className} onClick={handleShow} >{props.linkText}</a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="form_pop"
      >
       <Modal.Header>
        <div className='w-100'>
          <div className='row g-2'>
            <div className='col'>
              <Modal.Title>Add Furnishings and Amenities</Modal.Title>
            </div>
            <div className='col-auto'>
              <Button variant="close" onClick={handleClose} className='position-static'></Button>
            </div>
          </div>  
        </div>      
       </Modal.Header>
        <Modal.Body className='p-4'>
              <div className='row g-3'>
              <div className="col-12">
                        <label  className="form-label font-22">Flat Furnishing</label>
                        <div className='row g-3'>
                                                <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="DiningTable"/>
                                                            <label className="form-check-label w-135" htmlFor="DiningTable">
                                                                <i><img src={imgDiningTable} className='' alt="" /></i>
                                                                <span>Dining Table</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="WashingMachine"/>
                                                            <label className="form-check-label w-135" htmlFor="WashingMachine">
                                                                <i><img src={imgWashingMachine} className='' alt="" /></i>
                                                                <span>Washing Machine</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="Sofa"/>
                                                            <label className="form-check-label w-135" htmlFor="Sofa">
                                                                <i><img src={imgSofa} className='' alt="" /></i>
                                                                <span>Sofa</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="Microwave"/>
                                                            <label className="form-check-label w-135" htmlFor="Microwave">
                                                                <i className=''><img src={imgMicrowave} className='' alt="" /></i>
                                                                <span>Microwave</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="Stove"/>
                                                            <label className="form-check-label w-135" htmlFor="Stove">
                                                                <i className=''><img src={imgStove} className='' alt="" /></i>
                                                                <span>Stove</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="Fridge"/>
                                                            <label className="form-check-label w-135" htmlFor="Fridge">
                                                                <i><img src={imgFridge} className='' alt="" /></i>
                                                                <span>Fridge</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="WaterPurifier"/>
                                                            <label className="form-check-label w-135" htmlFor="WaterPurifier">
                                                                <i><img src={imgWaterPurifier} className='' alt="" /></i>
                                                                <span>Water Purifier</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="GasPipeline"/>
                                                            <label className="form-check-label w-135" htmlFor="GasPipeline">
                                                                <i><img src={imgGasPipeline} className='' alt="" /></i>
                                                                <span>Gas Pipeline</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="AC"/>
                                                            <label className="form-check-label w-135" htmlFor="AC">
                                                                <i className=''><img src={imgAc} className='' alt="" /></i>
                                                                <span>AC</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="bed"/>
                                                            <label className="form-check-label w-135" htmlFor="bed">
                                                                <i className=''><img src={imgBed} className='' alt="" /></i>
                                                                <span>Bed</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="TV"/>
                                                            <label className="form-check-label w-135" htmlFor="TV">
                                                                <i><img src={imgTv} className='' alt="" /></i>
                                                                <span>TV</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="Cupboard"/>
                                                            <label className="form-check-label w-135" htmlFor="Cupboard">
                                                                <i className=''><img src={imgCupboard} className='' alt="" /></i>
                                                                <span>Cupboard</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="FlatFurnishing" id="Geyser"/>
                                                            <label className="form-check-label w-135" htmlFor="Geyser">
                                                                <i className=''><img src={imgGeyser} className='' alt="" /></i>
                                                                <span>Geyser</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                    </div>

                    <div className="col-12">
                        <label  className="form-label font-22">Societv Amenities</label>
                        <div className='row g-3'>
                                                <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="Lift"/>
                                                            <label className="form-check-label w-135" htmlFor="Lift">
                                                                <i><img src={imgLift} className='' alt="" /></i>
                                                                <span>Lift</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="CCTV"/>
                                                            <label className="form-check-label w-135" htmlFor="CCTV">
                                                                <i><img src={imgCctv} className='' alt="" /></i>
                                                                <span>CCTV</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="Gym"/>
                                                            <label className="form-check-label w-135" htmlFor="Gym">
                                                                <i><img src={imgGym} className='' alt="" /></i>
                                                                <span>Gym</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="Garden"/>
                                                            <label className="form-check-label w-135" htmlFor="Garden">
                                                                <i className=''><img src={imgGarden} className='' alt="" /></i>
                                                                <span>Garden</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="KidsArea"/>
                                                            <label className="form-check-label w-135" htmlFor="KidsArea">
                                                                <i className=''><img src={imgKidsArea} className='' alt="" /></i>
                                                                <span>Kids Area</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="Sports"/>
                                                            <label className="form-check-label w-135" htmlFor="Sports">
                                                                <i><img src={imgSports} className='' alt="" /></i>
                                                                <span>Sports</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="SwimmingPool"/>
                                                            <label className="form-check-label w-135" htmlFor="SwimmingPool">
                                                                <i><img src={imgSwimmingPool} className='' alt="" /></i>
                                                                <span>Swimming Pool</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="Intercom"/>
                                                            <label className="form-check-label w-135" htmlFor="Intercom">
                                                                <i><img src={imgIntercom} className='' alt="" /></i>
                                                                <span>Intercom</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="GatedCommunity"/>
                                                            <label className="form-check-label w-135" htmlFor="GatedCommunity">
                                                                <i className=''><img src={imgGatedCommunity} className='' alt="" /></i>
                                                                <span>Gated Community</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="ClubHouse"/>
                                                            <label className="form-check-label w-135" htmlFor="ClubHouse">
                                                                <i className=''><img src={imgClubHouse} className='' alt="" /></i>
                                                                <span>Club House</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="CommunityHall"/>
                                                            <label className="form-check-label w-135" htmlFor="CommunityHall">
                                                                <i><img src={imgClubHouse} className='' alt="" /></i>
                                                                <span>Community Hall</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="RegularWaterSupply"/>
                                                            <label className="form-check-label w-135" htmlFor="RegularWaterSupply">
                                                                {/* <i className=''><img src={imgCupboard} className='' alt="" /></i> */}
                                                                <i className="fa-solid fa-faucet-drip"></i>
                                                                <span>Regular Water Supply</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="PowerBackup"/>
                                                            <label className="form-check-label w-135" htmlFor="PowerBackup">
                                                                {/* <i className=''><img src={imgGeyser} className='' alt="" /></i> */}
                                                                <i className="fa-solid fa-car-battery"></i>
                                                                <span>Power Backup</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="form-check rectBox icon-block h-100">
                                                            <input className="form-check-input" type="radio" name="SocietvAmenities" id="petAllowed"/>
                                                            <label className="form-check-label w-135" htmlFor="petAllowed">
                                                                {/* <i className=''><img src={imgGeyser} className='' alt="" /></i> */}
                                                                <i className="fa-solid fa-dog"></i>
                                                                <span>Pet Allowed</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                    </div>
              </div>
        </Modal.Body>
        
      </Modal>
    </>
  );
}

export default FormModelPopup;