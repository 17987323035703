import React from 'react';
import imgDummy from './../../images/gully-boy.png';
import MyModal from '../FormPopUp';

function PropertyBlock() {
  return (
    <>
      <div className="album_blk">
                <button className='btn-delete '></button>
                <div className="album_imgblk position-relative">
                  <img src={imgDummy} alt="Tripundra" />                                     
                </div>
                <div className="album_contblk d-flex flex-column justify-content-between align-items-start">
                    <h3 className="font-20 font-600 mb-0 text-start">Tripundra</h3>
                    <p className="text-gray-4b font-14 font-500 mb-2 text-start">by TARC Limited</p>                                            
                    <p className="mb-0 font-14 font-500 line-height-18 text-black">3, 4 BHK Apartments</p>
                    <p className="m-0 font-14 font-400 line-height-18 text-gray-84">Kapashera, Bijwasan Road, New Delhi</p>                                        
                </div>
                <div className="albumblk_footer d-flex ">
                    <div className="font-16 font-500"><span className="font-16 font-600 me-1">₹</span>4.84 Cr - 6.96 Cr</div>                                        
                </div>
            </div>
    </>
  )
}

export default PropertyBlock;
