import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropertyBlockWrap from './PropertyBlockWrap.tsx';


function ProfilePropertyList() {
    const navigate = useNavigate(); // Initialize useNavigate hook
          
      // Handle the button click event
      const handleClickBasicDetails = () => {
        navigate('/post-your-property'); // Navigate to the /rice-details page
      };
  return (
    <>
      <div className="tab-white-block">
        <div className="tab-white-block-header">
          <div className="row g-2 align-items-center">
            <div className="col text-start">
              <h2 className="text-start mb-0 font-22 font-500">Property List</h2>
            </div>   
            <div className="col-auto">
                <button className="btn btn-edit" onClick={handleClickBasicDetails}>
                    <i className="fa-solid fa-plus me-2"></i>
                    <span>Add</span>
                </button>
            </div>       
          </div>
        </div>
        <div className="tab-white-block-body">
            <PropertyBlockWrap/>
        </div>
      </div>
    </>
  )
}

export default ProfilePropertyList;
