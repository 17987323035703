import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CitySearch from './CitySearch';

function AddBasicDetails() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    propertyType: '',
    lookingTo: '',
    propertyName: '',
    city: '' // City will be set via CitySearch
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCitySelect = (city) => {
    console.log('Selected City:', city);
    setFormData((prevFormData) => ({
      ...prevFormData,
      city: city.name, // Update the city field with the selected city's name
    }));
  };

  const handleSubmit = async () => {

    console.log(formData, 'formData data');
    const { propertyType, lookingTo, propertyName, city } = formData;

    if (!propertyType || !lookingTo || !propertyName || !city) {
      setError('All fields are required.');
      return;
    }

    const sessionData = JSON.parse(localStorage.getItem('sessionData'));
    const userId = sessionData?.user?.id;

    const data = { userId, propertyType, lookingTo, propertyName, city };

    try {
      const response = await fetch('https://www.astsoftwaresolutions.in/api/products/addpostyourproperty', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      console.log(response)
      if (response.ok) {
        const result = await response.json();
            console.log('Inserted/Updated Property ID:', result.id); // Log the returned ID

            // Navigate to the details page with the property ID
            navigate(`/post-your-property-detail/${result.id}`);
        //navigate('/post-your-property-detail/');
      } else {
        setError('Failed to add property. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="row g-4">
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="col-12">
        <h2 className="font-24 mb-0 text-start">Add Basic Details</h2>
      </div>
      <div className="col-12">
        <label className="form-label">Property Type</label>
        <div className="row g-3">
          <div className="col-auto">
            <div className="form-check rectBox">
              <input
                className="form-check-input"
                type="radio"
                name="propertyType"
                value="Residential"
                id="Residential"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="Residential">Residential</label>
            </div>
          </div>
          <div className="col-auto">
            <div className="form-check rectBox">
              <input
                className="form-check-input"
                type="radio"
                name="propertyType"
                value="Commercial"
                id="Commercial"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="Commercial">Commercial</label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <label className="form-label">Looking to</label>
        <div className="row g-3">
          <div className="col-auto">
            <div className="form-check rectBox">
              <input
                className="form-check-input"
                type="radio"
                name="lookingTo"
                value="Rent"
                id="Rent"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="Rent">Rent</label>
            </div>
          </div>
          <div className="col-auto">
            <div className="form-check rectBox">
              <input
                className="form-check-input"
                type="radio"
                name="lookingTo"
                value="Sell"
                id="Sell"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="Sell">Sell</label>
            </div>
          </div>
          <div className="col-auto">
            <div className="form-check rectBox">
              <input
                className="form-check-input"
                type="radio"
                name="lookingTo"
                value="PG/Co-living"
                id="PG-Co-living"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="PG-Co-living">PG/Co-living</label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <input
          type="text"
          className="form-control"
          name="propertyName"
          placeholder="Property Name"
          onChange={handleChange}
        />
      </div>
      <div className="col-12">
        <CitySearch onCitySelect={handleCitySelect} />
      </div>
      <div className="col-12">
        <button className="btn btn-big-green" type="button" onClick={handleSubmit}>
          Next, add property details
        </button>
      </div>
    </div>
  );
}

export default AddBasicDetails;
