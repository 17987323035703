import React from 'react';

import PostPropertyStatus from './PostPropertyStatus.tsx';
import AddBasicDetails from './AddBasicDetails.tsx';


function PostYourProperty() {

  
  return (
    <>
    <i className='position-absolute p-5 bg-info bg-gradient w-100'></i>
      <div className="max-1140 mx-auto my-5 position-relative">
        
        <div className="postPropertyWrap">
          <div className="row g-4">
            <div className="col-md-auto">
              <PostPropertyStatus/>
            </div>
            <div className="col-md">
              <div className="postPropertyForm">
                <AddBasicDetails/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostYourProperty;
