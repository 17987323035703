import React from 'react';

function AddPriceDetails() {
    
  return (
    <>
      <div className='row g-4'>
                    <div className="col-12">
                        <a className='font-24 mb-0 text-start title-link' href='/post-your-property-document'><i className="bi bi-chevron-left me-2"></i>Add Price Details</a>
                    </div>                    

                    <div className="col-12">
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Monthly Rent"/>
                    </div>

                    <div className="col-12">
                        <input type="text" className="form-control form-date" id="exampleFormControlInput1" placeholder="Avaliable From"/>
                    </div>        

                    <div className="col-12">
                        <label  className="form-label">Security Deposit</label>
                        <div className='row g-3'>
                        <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="SecurityDeposit" id="None"/>
                                    <label className="form-check-label" htmlFor="None">
                                        <span>None</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="SecurityDeposit" id="1month"/>
                                    <label className="form-check-label" htmlFor="1month">
                                        <span>1 month</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="SecurityDeposit" id="2month"/>
                                    <label className="form-check-label" htmlFor="2month">
                                        <span>2 month</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="SecurityDeposit" id="custom"/>
                                    <label className="form-check-label" htmlFor="custom">
                                        <span>custom</span>
                                    </label>
                                </div>
                            </div>
                    
                
                        
                        </div>
                    </div>

                    <div className="col-12">
                        <label  className="form-label">Cost</label>
                        <input type="text" className="form-control form-rupee" id="exampleFormControlInput1" placeholder=""/>

                        <div className='highlight'>
                            <i className="fa-solid fa-lightbulb"></i>
                            <div>
                            Estimated price of your property based on price trends is around <span className='font-600'>₹ 46,01,250</span> - <span className='font-600'>₹ 56,23,750</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <label  className="form-label">Construction Status</label>
                        <div className='row g-3'>
                        <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="ConstructionStatus" id="ReadytoMove"/>
                                    <label className="form-check-label" htmlFor="ReadytoMove">
                                        <span>Ready to Move</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="form-check rectBox">
                                    <input className="form-check-input" type="radio" name="ConstructionStatus" id="UnderConstruction"/>
                                    <label className="form-check-label" htmlFor="UnderConstruction">
                                        <span>Under Construction</span>
                                    </label>
                                </div>
                            </div>
                            
                    
                
                        
                        </div>
                    </div>

                    <div className="col-12">
                        <button className="btn btn-big-green" type="submit">Post Property</button>
                    </div>

                </div>
    </>
  )
}

export default AddPriceDetails;
