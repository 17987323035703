import React from 'react';
import PropertyBlock from '../Home/PropertyBlock.tsx';

function PropertyBlockWrap() {
  return (
    <>
      <div className='w-100'>
                <div className='row g-4'>
                    <div className="col-md-4 col-sm-6">
                        <PropertyBlock/>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <PropertyBlock/>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <PropertyBlock/>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <PropertyBlock/>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <PropertyBlock/>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <PropertyBlock/>
                    </div>
                    
                </div>
            </div>
    </>
  )
}

export default PropertyBlockWrap;
