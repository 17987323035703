import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import ImgMale from '../../images/yellow-student-boy.svg';
import ImgFemale from '../../images/yellow-student.svg'; 
import QuillEditor from '../QuillEditor.tsx';
import CitySearch from '../PostYourProperty/CitySearch.jsx';

const ProfileViewEdit = () => {

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    category: null,
    country: null,
    state: null,
    city: null,
    propertyType: '',
    lookingTo: '',
    propertyName: '',
  });

  const storedUser = JSON.parse(sessionStorage.getItem('user'));
  console.log(storedUser);
  const user_id = storedUser ? storedUser.id : null; // Example UUID for API call

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://www.astsoftwaresolutions.in/api/users/getUsers/${user_id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data.data[0]);

        // Fetch categories, countries, etc.
        //const categoriesResponse = await axios.post('https://www.astsoftwaresolutions.in/api/categoryList');
        const formDataToSend = {
          webType: '1',
        };
        
        axios
          .post('https://www.astsoftwaresolutions.in/api/categoryList', formDataToSend, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {

            const categoriesResponse = response;
            setCategories(categoriesResponse.data);
            console.log('Response:', response.data);
          })
          .catch((error) => {
            console.error('Error:', error.response ? error.response.data : error.message);
          });
        const countriesResponse = await axios.get('https://www.astsoftwaresolutions.in/api/countries');

        
        setCountries(countriesResponse.data);

        setUserData(response.data.data[0]);  // Assuming the data is in response.data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    if (user_id) {
      fetchUserProfile();
    }
  }, [user_id]);


  // Fetch states when a country is selected
  useEffect(() => {
    if (formData.country) {
      axios
        .get(`https://www.astsoftwaresolutions.in/api/states?countryId=${formData.country.value}`)
        .then((response) => setStates(response.data))
        .catch((error) => console.error('Error fetching states:', error));
    }
  }, [formData.country]);

  // Fetch cities when a state is selected
  useEffect(() => {
    if (formData.state) {
      axios
        .get(`https://www.astsoftwaresolutions.in/api/cities?stateId=${formData.state.value}`)
        .then((response) => setCities(response.data))
        .catch((error) => console.error('Error fetching cities:', error));
    }
  }, [formData.state]);


  // State for edit mode
  const [isEditing, setIsEditing] = useState(false);

  // Handle input changes
  const handleInputChange = (field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleCitySelect = (city) => {
    console.log('Selected City:', city);
    setFormData((prevFormData) => ({
      ...prevFormData,
      city: city.name, // Update the city field with the selected city's name
    }));
  };
  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  // Save changes (Here you could also implement an actual save to the server)
  const saveChanges = () => {
    console.log('Saved data:', userData);
    setIsEditing(false);
  };

  // Cancel changes
  const cancelChanges = () => {
    setUserData(null);  // Clear data or reset to original state if needed
    setIsEditing(false);
  };

  if (loading) return <div className='d-flex justify-content-center align-items-center py-5'>
  <div className="loaderNew"></div>
</div>;

  return (
    <div className="tab-white-block">
      <div className='tab-white-block-header'>
        <div className='row g-2 align-items-center'>
            <div className='col text-start'>
              <h2 className='text-start mb-0 font-22 font-500'>Personal Info</h2>
            </div>
            
        </div>
      </div>

      <div className='tab-white-block-body'>
        
        
        <form className="" name="user_form" id="user_form" action="" method="post">                          
                                  
                                  <div className="row g-4 align-items-center">
                                  
                                      <div className="col-md-6">
                                          <label  className="form-label">First Name</label>
                                          <input type="text" className="form-control height-50" placeholder="Name*" name="FirstNamestd" id="FirstNamestd"/>
                                          <div className="error-feedback" id="FirstNamestdsp"></div>
                                      </div>

                                      <div className="col-md-6">
                                          <label  className="form-label">Last Name</label>
                                          <input type="text" className="form-control height-50" placeholder="Name*" name="FirstNamestd" id="FirstNamestd"/>
                                          <div className="error-feedback" id="FirstNamestdsp"></div>
                                      </div>

                                      <div className="col-md-6">
                                      <label  className="form-label">Date of Birth</label>
                                        <input type="text" className="form-control height-50" placeholder="Enter Location" name="ContactNumberstd" id="ContactNumberstd"/>
                                          <div id="ContactNumberstdsp" className="error-feedback"></div>
                                      </div>

                                      <div className='col-12'>
                                        <label  className="form-label">Info</label>
                                        <QuillEditor/>
                                    </div>

                                      <div className='col-12'>
                                        <label  className="form-label">Cover Description</label>
                                        <QuillEditor/>
                                    </div>

                                    <div className='col-12'>
                                    <label  className="form-label">Upload Images</label>
                                    <div className='uploadwrap'>
                                        <input type="file" multiple />
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <i className="bi bi-cloud-arrow-up-fill me-2"></i> <span>Drag and Drop Images here</span>
                                        </div>
                                    </div>
                                </div>
                                   <div className="col-md-6">
                                        <label  className="form-label">Gender</label>
                                          <div className='row g-3'>
                                            <div className='col-auto'>
                                            <div class="form-check">
  <input class="form-check-input" type="radio" name="Gender" id="Male"/>
  <label class="form-check-label" for="Male">
    Male
  </label>
</div>

                                            </div>
                                            <div className='col-auto'>
                                            <div class="form-check">
  <input class="form-check-input" type="radio" name="Gender" id="Female" checked/>
  <label class="form-check-label" for="Female">
    Female
  </label>
</div>
                                              </div>  
                                          </div>
                                      </div>
                                      {/* Category Select */}
                                      <div className="col-md-6">
                                        <label className="form-label">Select Category</label>
                                        <Select
                                          options={categories.map((category) => ({
                                            value: category.id,
                                            label: category.name,
                                          }))}
                                          value={formData.category}
                                          onChange={(selected) => handleInputChange("category", selected)}
                                          placeholder="Select a Category"
                                          isClearable // Adds a clear button to reset selection
                                        />
                                      </div>
                                      <div className="col-md-6">
                                      <label  className="form-label">Area of Operation</label>
                                      <CitySearch onCitySelect={handleCitySelect} />
                                        <div id="EmailAddressstdsp" className="error-feedback"></div>              
                                      </div>
                                      
                                      <div className="col-md-6">
                                          <label  className="form-label">Experience</label>
                                          <input type="text" className="form-control height-50" placeholder="Office Number" name="SchoolNamestd" id="SchoolNamestd"/>
                                          <div id="SchoolNamestdsp" className="error-feedback"></div>
                                      </div>
                                      <div className="col-md-6">
                                      <label  className="form-label">Proprietor ship</label>
                                        <input type="text" className="form-control height-50" placeholder="Office Address" name="ContactNumberstd" id="ContactNumberstd"/>
                                          <div id="ContactNumberstdsp" className="error-feedback"></div>
                                      </div>


                                      <div className="col-md-6">
                                      <label  className="form-label">Email address</label>
                                        <input type="email" className="form-control height-50" placeholder="Email address" name="ContactNumberstd" id="ContactNumberstd"/>
                                          <div id="ContactNumberstdsp" className="error-feedback"></div>
                                      </div>


                                      <div className="col-md-6">
                                      <label  className="form-label">Your Phone</label>
                                        <input type="text" className="form-control height-50" placeholder="Contact Number" name="ContactNumberstd" id="ContactNumberstd"/>
                                          <div id="ContactNumberstdsp" className="error-feedback"></div>
                                      </div>

                                      {/* Country Select */}
            {/* <div className="col-md-6">
              <label className="form-label">Country</label>
              <Select
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                value={formData.country}
                onChange={(selected) => handleInputChange('country', selected)}
                placeholder="Select a Country"
              />
            </div> */}

                                      {/* State Select */}
            {/* <div className="col-md-6">
              <label className="form-label">State</label>
              <Select
                options={states.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                value={formData.state}
                onChange={(selected) => handleInputChange('state', selected)}
                placeholder="Select a State"
                isDisabled={!formData.country}
              />
            </div> */}

                                      {/* City Select */}
            {/* <div className="col-md-6">
              <label className="form-label">City</label>
              <Select
                options={cities.map((city) => ({
                  value: city.id,
                  label: city.name,
                }))}
                value={formData.city}
                onChange={(selected) => handleInputChange('city', selected)}
                placeholder="Select a City"
                isDisabled={!formData.state}
              />
            </div> */}

                                      <div className="col-md-6">
                                      <label  className="form-label">Address</label>
                                        <input type="text" className="form-control height-50" placeholder="Enter Location" name="ContactNumberstd" id="ContactNumberstd"/>
                                          <div id="ContactNumberstdsp" className="error-feedback"></div>
                                      </div>
                                      
                                
                                      <div className="col-md-12">
                                          <div className="row align-items-center">

                                          <div className="col-auto"><button type="button" className="btn btn-submit radius-25" data-bs-target="#success_popup" onclick="return onForm_Submit()">Submit</button></div> 
              
                                              <div className="col-auto"><button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button></div>
                                              
                                              
                                              
                                          </div>
                                      </div>
                                  </div>
                                  
                                  
              
                              </form>
      </div>
   

      

 
    </div>
  );
};

export default ProfileViewEdit;
